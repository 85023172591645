// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getLoadingLazyAtYPosition, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as localizedValues from "./GPumX616Y-0.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["pDgdGU_IZ"];

const serializationHash = "framer-mn2au"

const variantClassNames = {pDgdGU_IZ: "framer-v-i6z831"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, mynd, width, ...props}) => { return {...props, acL31oO6O: mynd ?? props.acL31oO6O ?? {src: "https://framerusercontent.com/images/T1bQ96heHQzIsAT1VtA8JomFw.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/T1bQ96heHQzIsAT1VtA8JomFw.png?scale-down-to=512 512w,https://framerusercontent.com/images/T1bQ96heHQzIsAT1VtA8JomFw.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/T1bQ96heHQzIsAT1VtA8JomFw.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/T1bQ96heHQzIsAT1VtA8JomFw.png 2894w"}, I1ESfktjX: click ?? props.I1ESfktjX} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any;mynd?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, I1ESfktjX, acL31oO6O, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pDgdGU_IZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapzu9en = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (I1ESfktjX) {const res = await I1ESfktjX(...args);
if (res === false) return false;}
})

const onClickzg9lpc = activeVariantCallback(async (...args) => {
if (I1ESfktjX) {const res = await I1ESfktjX(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-i6z831", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"pDgdGU_IZ"} onTap={onTapzu9en} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 983, intrinsicWidth: 1447, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 898) - 0) - 792) / 2) + 0 + 0))), pixelHeight: 1966, pixelWidth: 2894, sizes: `calc(${componentViewport?.width || "100vw"} * 0.9)`, ...toResponsiveImage(acL31oO6O), ...{ positionX: "center", positionY: "center" }}} className={"framer-1qejre1"} data-framer-name={"image"} layoutDependency={layoutDependency} layoutId={"dpdvk6iQJ"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "0px 0.6021873017743928px 0.421531111242075px -0.6666666666666666px rgba(0, 0, 0, 0.1607), 0px 2.288533303243457px 1.60197331227042px -1.3333333333333333px rgba(0, 0, 0, 0.17385), 0px 10px 7px -2px rgba(0, 0, 0, 0.234)"}}><ComponentViewportProvider ><motion.div className={"framer-1ohfwgd-container"} layoutDependency={layoutDependency} layoutId={"ZGZdhRgCh-container"}><Phosphor color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"House"} iconSelection={"X"} id={"ZGZdhRgCh"} layoutId={"ZGZdhRgCh"} mirrored={false} onClick={onClickzg9lpc} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider></Image></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mn2au.framer-1txwv5u, .framer-mn2au .framer-1txwv5u { display: block; }", ".framer-mn2au.framer-i6z831 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 898px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1350px; }", ".framer-mn2au .framer-1qejre1 { align-content: center; align-items: center; aspect-ratio: 1.4720244150559512 / 1; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: var(--framer-aspect-ratio-supported, 136px); justify-content: center; overflow: visible; padding: 0px; position: relative; width: 90%; }", ".framer-mn2au .framer-1ohfwgd-container { cursor: pointer; flex: none; height: 40px; position: absolute; right: -20px; top: -20px; width: 40px; z-index: 10; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mn2au.framer-i6z831, .framer-mn2au .framer-1qejre1 { gap: 0px; } .framer-mn2au.framer-i6z831 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-mn2au.framer-i6z831 > :first-child { margin-top: 0px; } .framer-mn2au.framer-i6z831 > :last-child { margin-bottom: 0px; } .framer-mn2au .framer-1qejre1 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mn2au .framer-1qejre1 > :first-child { margin-left: 0px; } .framer-mn2au .framer-1qejre1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 898
 * @framerIntrinsicWidth 1350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"I1ESfktjX":"click","acL31oO6O":"mynd"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGPumX616Y: React.ComponentType<Props> = withCSS(Component, css, "framer-mn2au") as typeof Component;
export default FramerGPumX616Y;

FramerGPumX616Y.displayName = "ScreenShotOverlay";

FramerGPumX616Y.defaultProps = {height: 898, width: 1350};

addPropertyControls(FramerGPumX616Y, {I1ESfktjX: {title: "Click", type: ControlType.EventHandler}, acL31oO6O: {__defaultAssetReference: "data:framer/asset-reference,T1bQ96heHQzIsAT1VtA8JomFw.png?originalFilename=image.png&preferredSize=auto", description: "", title: "Mynd", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerGPumX616Y, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})